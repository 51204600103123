import * as actionTypes from '../actions/actionTypes';
import axios from 'axios';
import axiosClient from '../httpClient';
import { url } from '../components/Constants/Constants';
import { check_auth_error } from '../components/Utility/Utility';
import { fetchHotels } from '../commons/components/Filter/actions/actions';

export function setToInitial(){
  return {
    type: actionTypes.SET_TO_INITIAL
  }
}

export function updateAuthToken(authToken) {
  return {
    type: actionTypes.AUTH_TOKEN,
    payload: authToken,
  };
}

export function updateSelectedTab(tab) {
  return {
    type: actionTypes.SELECTED_TAB,
    payload: tab,
  };
}

export const fetchUserDetailSuccess = (user) => {
  return {
    type: actionTypes.FETCH_USER_DETAIL_SUCCESS,
    payload: user
  }
}

export function fetchUserDetail() {
  return function(dispatch, getState){
    const {
      filter: { selectedHotel,hotels },
      auth:{token}
    } = getState(); 
    if(!token)
      return
    const URL = selectedHotel?.hotel_code?`/accounts/user/?hotel=${selectedHotel?.hotel_code}`:`/accounts/user/`
    dispatch({type: actionTypes.FETCH_USER_DETAIL_REQUEST});
    axiosClient.get(URL)
    .then((res) => { dispatch(fetchUserDetailSuccess(res.data))
 
    }).catch((error) => {
      // check_auth_error(error);
      dispatch({type: actionTypes.FETCH_USER_DETAIL_ERROR});  
    });
  }
}

export function logout(){
  return function(dispatch, getState){
    axiosClient.post('/accounts/logout/', {}).
    then((res) => {console.log('Logout success'); 
      
      dispatch({
        type:actionTypes.UPDATE_FIREBASE_CRED,
        payload:{
          fcm_token:null,
          device_id:null
        }
      })
      dispatch(setToInitial())}).
    catch((error) => {console.log(`Error logging out: ${error}`); dispatch(setToInitial())});

  }
}

