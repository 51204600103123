import { format } from "date-fns";
import {
  COLOR_POSITIVE,
  COLOR_NEGATIVE,
  COLOR_MIXED,
  COLOR_NEUTRAL,
} from "./Constants";
import DeviceDetector from "device-detector-js";
import { setToInitial } from "../../actions/loginActions";
import store from "../../store";

export const getPlatformDetails = () => {
  const deviceDetector = new DeviceDetector();
  const userAgent = navigator.userAgent;
  return deviceDetector.parse(userAgent);
};
export function add_auth_header(header) {
  return (dispatch, getState) => {
    const { auth } = getState();

    if (!auth.token) {
      return;
    }

    if (header != undefined) {
      header.Authorization = "Token " + auth.token;
    } else {
      var header = { Authorization: "Token " + auth.token };
    }
    return header;
  };
}

export function check_auth_error(
  error,
  auth_url = "/login",
  attach_extra_params = null
) {
  if (
    error.response &&
    error.response.status &&
    (error.response.status === 401 || error.response.status === 403)
  ) {
    store.dispatch(setToInitial());
    var current = window.location.pathname;
    let redirect_url = "";
    if (current == auth_url) redirect_url = `${auth_url}`;
    else redirect_url = `${auth_url}?next=${current}`;

    if (attach_extra_params) {
      redirect_url =
        redirect_url +
        "?" +
        convert_filter_obj_to_query_param(attach_extra_params);
    }
    setTimeout(() => {
      window.location = redirect_url;
    }, [5000]);
  }
}

export function authenticate() {
  var current = window.location.pathname;
  window.location = `/login?next=${current}`;
}

export function attach_query_params(base_url) {
  var query_str = window.location.href.split("?")[1];
  if (query_str != undefined) {
    return base_url + "?" + query_str;
  } else {
    return base_url;
  }
}

export function append_or_replace_query_params(base_url, extra_param = {}) {
  // extra_params = {name: value}
  var query_str = window.location.href.split("?")[1];
  if (query_str != undefined) {
    const params = query_str.split("&");

    params.map((e) => {
      const key = e.split("=")[0];
      const value = e.split("=")[1];
      if (!extra_param[key]) {
        extra_param[key] = value;
      }
    });
  }
  let new_query_str = "";
  for (const [key, value] of Object.entries(extra_param)) {
    if (new_query_str) {
      new_query_str += `&${key}=${value}`;
    } else {
      new_query_str = `${key}=${value}`;
    }
  }

  return base_url + "?" + new_query_str;
}

export function get_query_param(param) {
  if (param === "next") {
    let url_str = new URL(window.location.href).search;
    let si = url_str.search("next=");

    if (si > 0) {
      return url_str.slice(si + 5);
    }
  }
  return new URL(window.location.href).searchParams.get(param);
}

export function round(value, decimal) {
  return value.toFixed(decimal);
}

export function get_percent_from_rating(rating, out_of) {
  return ((rating / out_of) * 100).toFixed(2);
}

export function getPercentFromRating(value, out_of) {
  if (!value || !out_of) {
    return 0;
  } else {
    return (value / out_of) * 100;
  }
}

export function getStrokeColor(percentage) {
  if (percentage >= 80) {
    return "#7FF17F";
  } else if (percentage < 80 && percentage >= 50) {
    return "#FFF45A";
  } else {
    return "#F17F7F";
  }
}

export function getStrokeColor2(percentage) {
  if (percentage >= 80) {
    return "#506E17";
  } else if (percentage < 80 && percentage >= 50) {
    return "#D9E622";
  } else {
    return "#FF4451";
  }
}

export function getPercColor(percentage) {
  if (percentage >= 80) {
    return "#00A110";
  } else if (percentage < 80 && percentage >= 50) {
    return "#E5DE11";
  } else {
    return "#FF6161";
  }
}

export function get_sentiment_color(sentiment) {
  const sentiment_colors = {
    Positive: "#7FF17F",
    Negative: "#FF6161",
    Neutral: "#FFF45A",
    Mixed: "#FFAD5A",
  };

  if (sentiment_colors[sentiment]) {
    return sentiment_colors[sentiment];
  } else {
    return "yellow";
  }
}

export function handle_redirect(resp) {
  if (resp.data.redirect != undefined) {
    window.location = resp.data.redirect;
  }
}

export function get_order_id() {
  var url_handler = new URL(window.location.href);
  var order_id = url_handler.searchParams.get("order_id");
  return order_id;
}

export function convert_filter_obj_to_query_param(obj) {
  return Object.keys(obj)
    .map((key, index) => {
      return `${key}=${obj[key]}`;
    })
    .join("&");
}

export function convert_filter_obj_to_query_param2(objs) {
  return objs
    .map((obj, index) => {
      return `${obj.query_key}=${obj.query_value}`;
    })
    .join("&");
}

export function convert_dates_to_query_params(option, startDate, endDate,Format="MM-dd-yyyy") {
  if (option == "Custom Date") {
    if (startDate == null) startDate = new Date();
    if (endDate == null) endDate = new Date();

    if (typeof startDate == "string") {
      startDate = new Date(startDate);
      endDate = new Date(endDate);
    }

    return `${format(startDate, Format)} to ${format(
      endDate,
      Format
    )}`;
  } else {
    return option;
  }
}

export function convert_date_to_str(option, startDate, endDate) {
  if (option == "Custom Date") {
    if (startDate == null) startDate = new Date();
    if (endDate == null) endDate = new Date();

    if (typeof startDate == "string") {
      startDate = new Date(startDate);
      endDate = new Date(endDate);
    }

    return `${format(startDate, "dd MMM, yy")} to ${format(
      endDate,
      "dd MMM, yy"
    )}`;
  } else {
    return option;
  }
}

export function reformat_date_str(date_str) {
  let new_str = date_str;

  try {
    new_str = `${format(new Date(date_str), "dd MMM, yyyy")}`;
  } catch (error) {
    console.log("Error while converting date: ", date_str);
  }
  return new_str;
}

export function reformat_date_str_with_time(date_str) {
  let new_str = date_str;

  try {
    new_str = `${format(new Date(date_str), "dd MMM, yyyy HH:MM A")}`;
  } catch (error) {
    console.log("Error while converting date: ", date_str);
  }
  return new_str;
}

export function reformat_time_str(date_str) {
  return `${format(new Date(date_str), "HH:MM A")}`;
}

export function extract_time_from_date(date_str) {
  const date = new Date(date_str);
  return `${date.getHours()}:${date.getMinutes()}`;
}

export function extract_date_from_datetime(date_str) {
  const date = new Date(date_str);
  const date_str_ = `${format(date, "yyyy-MM-dd")}`;
  return date_str_;
}

export function capitalize(str) {
  if (!str) {
    return str;
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function split_review(txt, token) {
  return txt.split(token);
}

export function get_hotel_obj(name, hotels, groups) {
  let obj = {};

  for (obj of groups) {
    if (obj.name == name) {
      return obj;
    }
  }

  for (obj of hotels) {
    if (obj.name == name) {
      return obj;
    }
  }
  return obj;
}

export function get_review_higlight_color(sentiment) {
  if (sentiment == "Positive") {
    return COLOR_POSITIVE;
  } else if (sentiment == "Negative") {
    return COLOR_NEGATIVE;
  } else if (sentiment == "Neutral") {
    return COLOR_NEUTRAL;
  } else if (sentiment == "Mixed") {
    return COLOR_MIXED;
  } else {
    return "#000000";
  }
}

export const tourStyles = {
  options: {
    primaryColor: "#7EA63F",
    textColor: "#fff",
    backgroundColor: "transparent",
    overlayColor: "rgba(0,0,0,0.7)",
    arrowColor: "#000",
  },
  buttonBack: {
    backgroundColor: "#7EA63F",
    color: "#fff",
    marginRight: "20px",
  },
  buttonNext: {
    borderRadius: "none",
  },
  tooltipTitle: {
    fontSize: 40,
    fontFamily: "Rockwell-bold",
    letterSpacing: "2px",
  },
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export function filter_empty_values(data) {
  let key;

  for (key of Object.keys(data)) {
    if (data[key] === "") {
      delete data[key];
    }
  }
  return data;
}

export const getValueType = (value) => {
  if (!value) {
    return;
  }

  if (!parseInt(value)) {
    return "Text";
  } else {
    return "Number";
  }
};

export const sortOrderHandler = (arr, key) => {
  if (!arr) {
    return;
  }

  if (arr.length === 0) {
    return 1;
  } else {
    return arr[arr.length - 1][key] + 1;
  }
};

export const deepCopy = (obj) => {
  if (!obj) return obj;

  return JSON.parse(JSON.stringify(obj));
};

export const updateObject = (obj_to_be_updated, new_obj) => {
  Object.assign(obj_to_be_updated, new_obj);
  return obj_to_be_updated;
};

export const findAndUpdateObj = (obj_arr, new_obj) => {
  const _old_obj = obj_arr.find((o) => o.id == new_obj.id);
  if (_old_obj) Object.assign(_old_obj, new_obj);
  return obj_arr;
};

export const filterSurveyWithLanguage = (list, filterLang) => {
  return list.filter((el, i) =>
    JSON.stringify(el.group.survey_languages).includes(filterLang)
  );
};

export const isValidJSON = (str) => {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
};


export const truncateMessage = (message,count) => {
  const maxLength = count ||  50;
  return message.length > maxLength
    ? message.substring(0, maxLength) + "..."
    : message;
};
