import { endOfDay, startOfYear } from "date-fns";
import { SET_TO_INITIAL } from "../../../actions/actionTypes";
import * as actionTypes from "./actions/actionTypes";

const initialState = {
  selectedHotel: {},
  hotels: {
    loading: false,
    data: [],
    error: "",
  },
  filters: {
    loading: false,
    data: [],
    error: "",
  },
  selectedFilters: {},
  filterTopics: {
    loading: false,
    data: [],
    error: "",
  },
  selectedFilterTopics: [],
  selectedCurrDate: {
    option: "Custom Date",
    startDate: startOfYear(new Date()),
    endDate: endOfDay(new Date()),
    label: null,
  },
  selectedCompDate: {
    option: "Same Period Last Year",
    startDate: null,
    endDate: null,
  },
  selectedDateRange: {
    option: "Custom Date",
    startDate: null,
    endDate: null,
  },
  selectedReviewId: null,
  selectedFiltersForComponent: [],
  filterForComponents: {
    loading: false,
    data: [],
    error: "",
  },
};

const filterReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_HOTEL_REQUEST:
      return {
        ...state,
        hotels: {
          ...state.hotels,
          loading: true,
        },
      };
    case actionTypes.FETCH_HOTEL_SUCCESS:
      return {
        ...state,
        hotels: {
          ...state.hotels,
          loading: false,
          data: action.payload,
          error: "",
        },
      };
    case actionTypes.FETCH_HOTEL_ERROR:
      return {
        ...state,
        hotels: {
          ...state.hotels,
          loading: false,
          error: action.payload,
        },
      };

    case actionTypes.SET_SELECTED_HOTEL:
      return {
        ...state,
        selectedHotel: action.payload,
      };

    case actionTypes.FETCH_FILTER_REQUEST:
      return {
        ...state,
        filters: {
          ...state.filters,
          loading: true,
        },
      };

    case actionTypes.FETCH_FILTER_SUCCESS:
      return {
        ...state,
        filters: {
          ...state.filters,
          data: action.payload,
          loading: false,
        },
      };

    case actionTypes.FETCH_FILTER_ERROR:
      return {
        ...state,
        filters: {
          ...state.filters,
          error: action.payload,
          loading: false,
        },
      };

    case actionTypes.SET_SELECTED_FILTERS:
      console.log(action)

let pervData = state.selectedFilters

      if (action.payload instanceof Array) {
       pervData[action.key] = action.payload
      }
      else {
       pervData[action.key] =pervData[action.key]? pervData[action.key].filter(  (_f) => _f.query_key != action.payload.query_key):[]
  
       pervData[action.key].push(action.payload);
      }

      return {
        ...state,
        selectedFilters: pervData,
      };

      case actionTypes.UNSET_SELECTED_FILTER:
        console.log(action)
        let prevD = state.selectedFilters || {}
         prevD[action.key] = prevD[action.key]
            ? prevD[action.key].filter(
                (_f) => _f.query_key != action.payload.query_key
              )
            : [];
        return {
          ...state,
          selectedFilters: prevD,
        };


        case actionTypes.SET_SELECTED_FILTERS_FOR_COM:
          if (action.payload instanceof Array) var _filtered = action.payload;
          else {
            var _filtered = state.selectedFiltersForComponent
              ? state.selectedFiltersForComponent.filter(
                  (_f) => _f.query_key != action.payload.query_key
                )
              : [];
            _filtered.push(action.payload);
          }
    
          return {
            ...state,
            selectedFiltersForComponent: _filtered,
          };
    
          case actionTypes.UNSET_SELECTED_FILTER_FOR_COM:
            console.log(action,!action?.payload?.isAll === undefined)
            let _filteredData = []
            if(!action?.payload?.isAll){
              _filteredData = state.selectedFiltersForComponent
              ? state.selectedFiltersForComponent.filter(
                  (_f) => _f.query_key != action.payload.query_key
                )
              : [];
                  console.log(_filteredData)
            }

            return {
              ...state,
              selectedFiltersForComponent: _filteredData,
            };
    

    case actionTypes.FETCH_FILTER_TOPICS_REQUEST:
      return {
        ...state,
        filterTopics: {
          ...state.filterTopics,
          loading: true,
        },
      };

    case actionTypes.FETCH_FILTER_TOPICS_SUCCESS:
      return {
        ...state,
        filterTopics: {
          ...state.filterTopics,
          data: action.payload,
          loading: false,
        },
      };

    case actionTypes.FETCH_FILTER_TOPICS_ERROR:
      return {
        ...state,
        filterTopics: {
          ...state.filterTopics,
          data: action.payload,
          loading: false,
        },
      };

      case actionTypes.FETCH_FILTER_SUCCESS_FOR_COM:        
        return {
          ...state,
          filterForComponents: {
            data: action.payload,
            loading: false,
            error:"",
          },
          selectedFiltersForComponent:[]
        };
  
      case actionTypes.FETCH_FILTER_ERROR_FOR_COM:
        return {
          ...state,
          filterForComponents: {
            data: [],
            loading: false,
            error:action.payload,
          },
          selectedFiltersForComponent:[]
        };
    case actionTypes.SET_SELECTED_FILTER_TOPICS:
      return {
        ...state,
        selectedFilterTopics: action.payload,
      };

    case actionTypes.SET_CURR_DATE:
      return {
        ...state,
        selectedCurrDate: action.payload,
      };

    case actionTypes.SET_COMP_DATE:
      return {
        ...state,
        selectedCompDate: action.payload,
      };

    // case actionTypes.SET_SELECTED_STAT_OPTION:
    //     return{
    //         ...state,
    //         selectedDate:{
    //         ...state.selectedDate,
    //         selectedStatOption:action.payload
    //         }
    //     }

    case actionTypes.SET_SELECTED_STAT_START_DATE:
      return {
        ...state,
        selectedDate: {
          ...state.selectedDateRange,
          startDate: action.payload,
        },
      };

    case actionTypes.SET_SELECTED_STAT_END_DATE:
      return {
        ...state,
        selectedDate: {
          ...state.selectedDateRange,
          endDate: action.payload,
        },
      };

    // case actionTypes.SET_SELECTED_COMP_OPTION:
    //     return{
    //         ...state,
    //         selectedDate:{
    //         ...state.selectedDate,
    //         selectedCompOption:action.payload
    //         }
    //     }

    // case actionTypes.SET_SELECTED_COMP_START_DATE:
    //     return{
    //         ...state,
    //         selectedDate:{
    //         ...state.selectedDate,
    //         selectedCompStartDate:action.payload
    //         }
    //     }

    // case actionTypes.SET_SELECTED_COMP_END_DATE:
    //     return{
    //         ...state,
    //         selectedDate:{
    //         ...state.selectedDate,
    //         selectedCompEndDate:action.payload
    //         }
    //     }

    case actionTypes.SET_SELECTED_REVIEWID:
      return {
        ...state,
        selectedReviewId: action.payload,
      };

    case SET_TO_INITIAL:
      return initialState;

    default:
      return state;
  }
};

export default filterReducer;
