import axios from 'axios';
import { url } from './components/Constants/Constants';
import store from './store'
import { SET_GLOBAL_MESSAGE } from './actions/actionTypes';
import { setGlobalMessage } from './actions/dashboardActions';


const axiosClient = axios.create({
    baseURL: url,
})

axiosClient.interceptors.request.use(function (config) {
    const token = store.getState().auth.token;
    
    if (token) {
        config.headers.Authorization =  `Token ${token}`;
    }
     
    return config;
});


// axiosClient.interceptors.response.use(
//     function (response) {
//         return response;
//     },
//     function (error) {

//         if (error.response) {

//             const { status, data } = error.response;
//             store.dispatch(setGlobalMessage({
//                 mess: data?.data || "Something went wrong",
//                 type: 'error'
//             }));
//         } else if (error.request) {

//             console.error('Request made but no response received:', error.request);
//             store.dispatch(setGlobalMessage({
//                 mess: "Request made but no response received",
//                 type: 'error'
//             }));
//         } else {
//             store.dispatch(setGlobalMessage({
//                 mess: "Error setting up request",
//                 type: 'error'
//             }));
//             console.error('Error setting up request:', error.message);
//         }

//         return Promise.reject(error);
//     }
// );

export default axiosClient