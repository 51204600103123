import * as actionTypes from '../actions/actionTypes';

const initialState = {
  token: null,
  user: {}
}

const authReducer = (state = initialState, action) => {
  switch(action.type){
    case actionTypes.AUTH_TOKEN:
      return{
          ...state,
          token: action.payload
        }
    
    case actionTypes.FETCH_USER_DETAIL_SUCCESS:
      return {
        ...state,
        user: action.payload
      }
        
    case actionTypes.SET_TO_INITIAL:
      return initialState

    default:
      return state;
  }
}

export default authReducer;