import * as chatActionTypes from "./actions/chatActionTypes";
import * as actionTypes from "../../actions/actionTypes";
import notificationSound from "../../assets/notification_sound.mp3";
import { sortMessagesByCreatedAt } from "../../commons/utility";

const initialState = {
  loading: false,
  loading_onclick: false,
  users: [],
  guest_count:0,
  guest_next:null,
  guest_prev:null,
  new_user_notification: 0,
  human_interaction_users: [],
  ongoing_users: [],
  history_users: [],
  selectedUsers: [],
  broadCastList:[],
  selectedBroadCast:[],
  selectedGroup_channels: [],
  group_messages:[],
  error: "",
  users_messages: [], // user_id: messages
  selectedUser_channels: [],
  selected_channel: "",
  selected_channel_data:{},
  successMessage:"",
  suggestedReply:[],
  analytics:{
    chatResolutionOverAllData:{},
    guestEngagement:[],
    intents:[],
    guestPerDay:[],
    totalMessageByChannel:[],
    totalStaffChatResponse:[],
    staffMessageResolutionTime:[],
    topChatTopic:[],
    totalMessageByChannelFromCampaign:[],
    totalMessageByCampaignNameFromCampaign:[],  
    chatResolutionTimeline:[],
    chatResolution:[],
    guestSurveyRatingBySurveyType:[],
    campignNameFilter:""
  }
};

function update_or_add_user_in_list(lst, user, add_if_not_present = true) {
  if (!lst) return add_if_not_present ? [user] : [];

  var _old_user_indx = lst.findIndex((_u) => _u.user_id == user.user_id);

  if (_old_user_indx == -1)
    return add_if_not_present ? [...lst, user] : [...lst];

  var _filtered_users = lst.filter((_u) => _u.user_id != user.user_id);
  _filtered_users.splice(_old_user_indx, 0, user);

  return _filtered_users;
}

function update_msg_in_list(lst, msg, add = false) {
  if (!lst) return add ? [msg] : [];

  let id = msg.feId || msg.id;
  var _old_idx = lst.findIndex((_m) => _m.id == id);

  if (_old_idx == -1) return add ? [...lst, msg] : [...lst];

  var _filtered_msgs = lst.filter((_m) => _m.id != id);
  _filtered_msgs.splice(_old_idx, 0, msg);
  return _filtered_msgs;
}

// function update_unsent_msg_in_list(lst, msg, old_msg_id) {
//   var _old_idx = lst.findIndex((_m) => _m.id == old_msg_id);

//   if (_old_idx == -1) return [...lst, msg];

//   var _filtered_msgs = lst.filter((_m) => _m.id != old_msg_id);
//   _filtered_msgs.splice(_old_idx, 0, msg);
//   return _filtered_msgs;
// }

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case chatActionTypes.FETCH_USERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case chatActionTypes.FETCH_USERS_SUCCESS:

      return {
        ...state,
        loading: false,
        users: action.payload.users.results,
        guest_count:action.payload.users.count,
        guest_next:action.payload.users.next,
        guest_prev:action.payload.users.previous,
        human_interaction_users: action.payload.users._ha,
        new_user_notification:action.payload.users._ha.length,
        ongoing_users: action.payload.users._on,
        history_users: action.payload.users._hi,
        error: "",
      };
      case chatActionTypes.FETCH_USERS_SUCCESS_APPEND:
        return {
          ...state,
          loading: false,
          users: [...state.users, ...action.payload.users.results],
          guest_count:action.payload.users.count,
          guest_next:action.payload.users.next,
          guest_prev:action.payload.users.previous,
          human_interaction_users:[...state.human_interaction_users,...action.payload.users._ha],
          new_user_notification: [...state.human_interaction_users,...action.payload.users._ha].length,
          ongoing_users:[...state.ongoing_users, ...action.payload.users._on],
          history_users:[...state.history_users,...action.payload.users._hi],
          error: "",
        };

    case chatActionTypes.FETCH_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        users: [],
        error: action.payload,
      };

      case chatActionTypes.FETCH_BROADCAST_FAILURE:
        return {
          ...state,
          loading: false,
          broadCastList: [],
          error: action.payload,
        };

    case chatActionTypes.SET_LOADING_ONCLICK:
      return {
        ...state,
        loading_onclick: action.payload,
      };

    case chatActionTypes.UPDATE_NEW_USER_NOTIFICATION:
      return {
        ...state,
        new_user_notification:
          state.new_user_notification > 0 ? state.new_user_notification - 1 : 0,
      };

    case chatActionTypes.SET_SELECTED_CHAT_USER:
      var filtered_selected_users = update_or_add_user_in_list(
        state.selectedUsers || [],
        action.payload
      );

      return {
        ...state,
        selectedUsers: filtered_selected_users,
      };

    case chatActionTypes.UNSET_SELECTED_CHAT_USER:
      return {
        ...state,
        users_messages: [],
        error: "",
        selectedUsers: [
          ...state.selectedUsers.filter(
            (_u) => _u.user_id != action.payload.user_id
          ),
        ],
      };
      case chatActionTypes.SET_SELECTED_CHAT_BROADCAST:

      return {
        ...state,
       selectedBroadCast : [action.payload],
       selectedUsers:[]
      };

    case chatActionTypes.UNSET_SELECTED_CHAT_BROADCAST:
      return {
        ...state,
        // users_messages: [],
        // error: "",
        selectedBroadCast: [],
      };

    case chatActionTypes.UPDATE_CHAT_USER:
      var filtered_users = update_or_add_user_in_list(
        state.users,
        action.payload,
        false
      );
      var selected_filtered_users = update_or_add_user_in_list(
        state.selectedUsers,
        action.payload,
        false
      );

      return {
        ...state,
        users: filtered_users,
        selectedUsers: selected_filtered_users,
        loading_onclick: false,
      };

    case chatActionTypes.UPDATE_CHAT_USER_PROP:
      var _user_indx = state.users.findIndex(
        (_u) => _u.user_id == action.payload.user_id
      );
      var _selected_user_indx = state.selectedUsers
        ? state.selectedUsers.findIndex(
            (_u) => _u.user_id == action.payload.user_id
          )
        : -1;

      var filterd_users = [...state.users];
      var selected_filterd_users = state.selectedUsers
        ? [...state.selectedUsers]
        : [];

      for (var k of Object.keys(action.payload.delete))
        if (_user_indx != -1) delete filterd_users[_user_indx][k];
      if (_selected_user_indx != -1)
        delete selected_filterd_users[_selected_user_indx][k];

      for (var k of Object.keys(action.payload.add))
        if (_user_indx != -1)
          filterd_users[_user_indx][k] = action.payload.add[k];
      if (_selected_user_indx != -1)
        selected_filterd_users[_selected_user_indx][k] = action.payload.add[k];

      return {
        ...state,
        users: filterd_users,
        selectedUsers: selected_filterd_users,
      };

    case chatActionTypes.UPDATE_OR_ADD_CHAT_USER_AND_RESHUFFLE:
      var _updated_user = action.payload.user;
      var _state_to_update = {};

      var _filtered_full_users = update_or_add_user_in_list(
        state.users,
        _updated_user
      );
      _state_to_update = {
        users: _filtered_full_users,
        new_user_notification: state.new_user_notification,
      };

      var _filtered_users2 = [],
        _usr_idx2 = null,
        _user2_removed_from = "";
      for (var [_idx, _users] of [
        state.human_interaction_users || [],
        state.ongoing_users || [],
        state.history_users || [],
      ].entries()) {
        _usr_idx2 = _users.findIndex(
          (_u) => _u.user_id == _updated_user.user_id
        );
        if (_usr_idx2 > -1) {
          console.log(`user found indx cat: ${_idx} and at idx2: ${_usr_idx2}`);
          _filtered_users2 = _users.filter(
            (_u) => _u.user_id != _updated_user.user_id
          );
          if (_idx == 0) {
            _state_to_update["human_interaction_users"] = _filtered_users2;
            _user2_removed_from = "human_interaction_users";
          } else if (_idx == 1) {
            _state_to_update["ongoing_users"] = _filtered_users2;
            _user2_removed_from = "ongoing_users";
          } else {
            _state_to_update["history_users"] = _filtered_users2;
            _user2_removed_from = "history_users";
          }
          break;
        }
      }

      if (_updated_user.requires_assistance) {
        // already in requires assistance list, so just update properties
        if (_user2_removed_from == "human_interaction_users") {
          _state_to_update["human_interaction_users"].splice(
            _usr_idx2,
            0,
            _updated_user
          );
        } else {
          _state_to_update["human_interaction_users"] = [
            ...state.human_interaction_users,
            _updated_user,
          ];
          _state_to_update["new_user_notification"] += 1;
          const notification_sound = new Audio(notificationSound);
          _state_to_update['successMessage'] = `${_updated_user?.first_name || ""} ${_updated_user?.last_name} is added to demand interaction`
          notification_sound.play().catch((error) => {
            console.error("Autoplay was prevented:", error);
          });
        }
      } else if (!_updated_user.chat_closed) {
        // user is in ongoing group
        if (_user2_removed_from == "ongoing_users")
          _state_to_update["ongoing_users"].splice(_usr_idx2, 0, _updated_user);
        else {
          if (_user2_removed_from == "human_interaction_users")
            _state_to_update["new_user_notification"] -= 1;
          _state_to_update["ongoing_users"] = [
            _updated_user,
            ...state.ongoing_users,
          ];
        }
      } else {
        if (_user2_removed_from == "history_users")
          _state_to_update["history_users"].splice(_usr_idx2, 0, _updated_user);
        else {
          if (_user2_removed_from == "human_interaction_users")
            _state_to_update["new_user_notification"] -= 1;
          _state_to_update["history_users"] = [
            _updated_user,
            ...state.history_users,
          ];
        }
      }

      return {
        ...state,
        ..._state_to_update,
        loading_onclick: false,
      };

    case chatActionTypes.FETCH_USER_MESSAGES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case chatActionTypes.FETCH_USER_MESSAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        error:
          action.payload.length == 0
            ? "No chat messages found"
            : action.payload.length < 10
            ? "--"
            : "",
        users_messages: action.payload,
      };
      case chatActionTypes.FETCH_BROADCAST_MESSAGES_SUCCESS:
        return {
          ...state,
          loading: false,
          error:
            action.payload.length == 0
              ? "No chat messages found"
              : "",
          group_messages: action.payload,
        };
    case chatActionTypes.FETCH_USER_MESSAGES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case chatActionTypes.PREPEND_USER_MESSAGES:
      let key = action.chatType==="broadcast"?"group_messages":"users_messages"
      console.log(action.payload.length)
      return {
        ...state,
        loading: false,
        error: action.payload.length === 0 ? "--" : "",
        [key]: [...action.payload, ...(state[key] || [])],
      };

    case chatActionTypes.ADD_TO_USER_MESSAGES:
      // let data = sortMessagesByCreatedAt([...(state.users_messages || []), action.payload])
      let data = [...(state.users_messages || []), action.payload]
      return {
        ...state,
        users_messages: data,
      };

      case chatActionTypes.ADD_TO_GROUP_MESSAGES:
        return {
          ...state,
          group_messages: [...(state.group_messages || []), action.payload],
          error:""
        };

    case chatActionTypes.UPDATE_USER_MESSAGE:
      var _usr_msgs = state.users_messages || [];
      var filtered_usr_msgs = update_msg_in_list(
        _usr_msgs,
        action.payload.message,
        action.payload.add
      );

      return {
        ...state,
        users_messages: filtered_usr_msgs,
      };

      case chatActionTypes.UPDATE_GROUP_MESSAGE:
        var _usr_msgs = state.group_messages || [];
        var filtered_usr_msgs = update_msg_in_list(
          _usr_msgs,
          action.payload.message,
          action.payload.add
        );
  
        return {
          ...state,
          group_messages: filtered_usr_msgs,
        };

    case chatActionTypes.UNSET_USER_MESSAGES:
      return {
        ...state,
        users_messages: [],
        error: "",
      };

      case chatActionTypes.UNSET_Group_MESSAGES:
        return {
          ...state,
          group_messages: [],
          error: "",
        };

    case chatActionTypes.FETCH_CHANNELS_SUCCESS:
      return {
        ...state,
        selectedUser_channels: action.payload,
      };

      case chatActionTypes.FETCH_GROUP_CHANNELS_SUCCESS:
        return {
          ...state,
          selectedGroup_channels: action.payload,
        };

    case chatActionTypes.SET_SELECTED_CHANNEL:
      let channels  = state[action.key]
      let _i = channels.find((e)=>e.code === action.payload)
      let _d = {}
      if(_i)
        _d = _i
      else if(action.payload && channels.length>0)
        _d = channels[0]

      return {
        ...state,
        selected_channel_data: _d,
        selected_channel: _d.code || "",
      };

      case chatActionTypes.EDIT_GUEST_REQ_SUC:
        return {
          ...state,
          successMessage: action.payload,
        };
    case chatActionTypes.UPDATE_SUGGESTED_REPLY:{
      let  data = state.suggestedReply
        switch (action.payload.type){
          case "add":
            data = [...state.suggestedReply,action.payload.data]
            break;
          case "delete":
            console.log(action.payload,state.suggestedReply)
            data = state.suggestedReply.filter((e)=>e.id!==action.payload.id )
            break;
          case "bulk_delete":
            data = state.suggestedReply.filter((e)=>e.user!==action.payload?.user)
            break;
          default:
            console.log(action.payload);
        }
      return {
        ...state,
        suggestedReply: data
     };  
    }
    case chatActionTypes.SAVE_CHAT_RESOLUTION:
      return {
        ...state,
        analytics:{
          ...state.analytics,
          chatResolutionOverAllData:action.payload
        },
      };
      case chatActionTypes.SAVE_GUEST_ENAGGEMENT:
        return {
          ...state,
          analytics:{
            ...state.analytics,
            guestEngagement:action.payload
          },
        };
        case chatActionTypes.SAVE_GUEST_ENAGGEMENT:
        return {
          ...state,
          analytics:{
            ...state.analytics,
            guestEngagement:action.payload
          },
        };
        case chatActionTypes.SAVE_INTENTS:
          return {
            ...state,
            analytics:{
              ...state.analytics,
              intents:action.payload
            },
          };
          case chatActionTypes.SET_NEW_NOTI_USER_FROM_API:
            return {
              ...state,
              new_user_notification:action.payload
            }; 
            case chatActionTypes.UPDATE_MESSAGE:
              return {
                ...state,
                users_messages:state.users_messages.map(msg =>
                  msg.id === action.payload.id ? action.payload : msg
                ),
              };      
    case chatActionTypes.SAVE_CHAT_ANALYTICS_DATA: {
      let key
      switch (action.payload.type) {
        case "unique-guests-per-day":
          key = "guestPerDay"
          break
        case "total-msgs-count-by-channel":
          key = "totalMessageByChannel"
          break
        case "total-staff-chat-responses":
          key = "totalStaffChatResponse"
          break
          case "staff-msgs-resolution-time":
            key = "staffMessageResolutionTime"
            break
        case "top-chat-topic":
              key = "topChatTopic"
              break
          case "campaign-msgs-count-by-channel":
                key = "totalMessageByChannelFromCampaign"
                break
                case "campaign-msgs-count-by-name":
                  key = "totalMessageByCampaignNameFromCampaign"
                  break
                  case "guest-survey-rating-by-survey-type":
                    key = "guestSurveyRatingBySurveyType"
                    break
    case "chat-resolutions-timeline":
       key = "chatResolutionTimeline"
      break
      case "chat-resolutions":
        key = "chatResolution"
       break
        default:
          return state
      }
      return {
        ...state,
        analytics: {
          ...state.analytics,
          [key]: action.payload.data
        }
      }
    }
    case chatActionTypes.UPADATE_CAMPAIGN_NAME_FILTER:
      console.log(action)
      return {
        ...state,
        analytics: {
          ...state.analytics,
          campignNameFilter: action.payload
        }
      };
    case chatActionTypes.SET_BROADCAST_LIST:
      return {
        ...state,
        broadCastList: action.payload,
        loading:false
      };
    case actionTypes.SET_TO_INITIAL:
      return initialState;

    default:
      return state;
  }
};

export default chatReducer;
