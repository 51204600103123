export const FETCH_SOURCES_REQUEST = "FETCH_SOURCES_REQUEST";
export const FETCH_SOURCES_SUCCESS = "FETCH_SOURCES_SUCCESS";
export const FETCH_SOURCES_ERROR = "FETCH_SOURCES_ERROR";
export const SET_SELECTED_SOURCE = "SET_SELECTED_SOURCE";
export const UPDATE_SOURCE = "UPDATE_SOURCE";

export const FETCH_REDIRECTION_URI_SUCCESS = "FETCH_REDIRECTION_URI_SUCCESS";
export const FETCH_REDIRECTION_URI_ERROR = "FETCH_REDIRECTION_URI_ERROR";

export const CONNECTING_SOURCE_REQUEST = "CONNECTING_SOURCE_REQUEST";
export const CONNECTING_SOURCE_SUCCESS = "CONNECTING_SOURCE_SUCCESS";
export const CONNECTING_SOURCE_ERROR = "CONNECTING_SOURCE_ERROR";
export const SET_PAGE_OR_LOC_SUCCESS = "SET_PAGE_OR_LOC_SUCCESS"
export const SET_PAGE_OR_LOC_ERROR = "SET_PAGE_OR_LOC_ERROR"

export const SET_TO_INITIAL = "SET_TO_INITIAL";
export const FETCH_STAFF_LIST_SUCCES = "FETCH_STAFF_LIST_SUCCES";
export const Add_NEW_STAFF_Req_Success = "Add_New_Staff_Req_Success";
export const Set_Campaign_List = "Set_Campaign_List";
export const FETCH_EMAIL_TRANSACTION_LIST_SUCCES = "FETCH_EMAIL_TRANSACTION_LIST_SUCCES";
export const FETCH_SMS_TRANSACTION_LIST_SUCCES = "FETCH_SMS_TRANSACTION_LIST_SUCCES";
export const RESET_STORE = "RESET_STORE";
export const FETCH_Scrap_Url_LIST_SUCCES = "FETCH_Scrap_Url_LIST_SUCCES";
export const FETCH_PDF_LIST_SUCCES = "FETCH_PDF_LIST_SUCCES";
export const FETCH_REQ_FOR_SETTINGS_TALBLES = "FETCH_REQ_FOR_SETTINGS_TALBLES";
export const SET_CONNECTING_TO_INITIAL = "SET_CONNECTING_TO_INITIAL";
export const SET_EMAIL_LOADING_FALSE = "SET_EMAIL_LOADING_FALSE";
export const SET_SMS_LOADING_FALSE = "SET_SMS_LOADING_FALSE";
export const SET_STAFF_LOADING_FALSE = "SET_STAFF_LOADING_FALSE";
export const SET_PLACE_EVENT_DATA = "SET_PLACE_EVENT_DATA";
export const SET_PLACE_EVENT_LOADING = "SET_PLACE_EVENT_LOADING";
export const FETCH_MESSAGE_TEMPLETE_LIST_SUCCES = "FETCH_MESSAGE_TEMPLETE_LIST_SUCCES";
export const FETCH_TEXT_DATA_SUCCESS = "FETCH_TEXT_DATA_SUCCESS";
export const FETCH_CUSTOM_FILTER_DATA_SUCCESS = "FETCH_CUSTOM_FILTER_DATA_SUCCESS";
export const FETCH_CUSTOM_FILTER_DATA_FAILURE = "FETCH_CUSTOM_FILTER_DATA_FAILURE";


