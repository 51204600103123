import axiosClient from '../httpClient';
import * as actionTypes from './actionTypes';

export const setDateParams = params => {

    return {
        type: actionTypes.SET_DATE_PARAMS,
        payload: params
    }
}

export const setFilterParams = params => {
    return {
        type: actionTypes.SET_FILTER_PARAMS,
        payload: params
    }
}


export const setGlobalMessage = (mess) => {
    return {
        type: actionTypes.SET_GLOBAL_MESSAGE,
        payload: mess
    }
}

export const setCommonTableLoading = (d) => {
    return {
        type: actionTypes.SET_COMMON_TABLE_LOADING,
        payload: d
    }
}


export const CommonHandlerForFetch = (url,setData,resultType="singleObjArr") => {
    return (dispatch, getState) => {
      axiosClient
        .get(url)
        .then((response) => {
            if(setData){
                    switch (resultType) {
                        case "singleObjArr":
                            dispatch(setData(response.data[0]));
                            break;
                    
                        default:
                            dispatch(setData(response.data));
                            break;
                    }
            }
        
        })
        .catch((error) => {
         console.log(error)
        });
    };
  };