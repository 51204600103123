import * as actionTypes from "./actions/actionTypes";
import { SET_TO_INITIAL } from "../../actions/actionTypes";

const initialState = {
  reviews: {
    loading: false,
    error: "",
    data: [],
    comments: {},
    hasMore: false,
    deleting_comment: false,
    editing_comment: {},
  },

  replies: {
    loading: false,
    data: {},
    send_success: false,
  },

  langOptions: [],
  aggregateScore:{}
};

const add_new_comments = (list, new_comments) => {
  return list ? [...list.data, ...new_comments] : new_comments;
};

const add_or_remove_liked_user = (list, data) => {
  const comments = list.data;
  const idx = comments.findIndex((_c) => _c.id == data.comment_id);
  const comment = comments[idx];
  const like_users = comment.like_users;
  let updated_comment;
  if (comment.user_liked) {
    updated_comment = {
      ...comment,
      user_liked: false,
      like_users: like_users.filter((_u) => _u.user.email != data.user.email),
    };
  } else {
    updated_comment = {
      ...comment,
      user_liked: true,
      like_users: [{ user: data.user }, ...like_users],
    };
  }
  comments.splice(idx, 1, updated_comment);
  return { ...list, data: comments };
};

const update_or_filter_comment = (
  list,
  old_comment_id,
  edit = false,
  new_comment = null
) => {
  const comments = list.data;
  let total_comments = list.count;
  const old_idx = comments.findIndex((c) => c.id == old_comment_id);
  const filtered_comments = comments.filter((c) => c.id != old_comment_id);

  if (edit) filtered_comments.splice(old_idx, 0, new_comment);
  else total_comments -= 1;

  return { ...list, data: filtered_comments, count: total_comments };
};

const reviewsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_REVIEWS_REQUEST:
      return {
        ...state,
        reviews: {
          ...state.reviews,
          loading: true,
          error: "",
        },
      };

    case actionTypes.FETCH_REVIEWS_ERROR:
      return {
        ...state,
        reviews: {
          ...state.reviews,
          loading: false,
          error: action.payload,
          // data: [],
        },
      };

    case actionTypes.FETCH_REVIEWS_SUCCESS:
      return {
        ...state,
        reviews: {
          ...state.reviews,
          loading: false,
          hasMore: true,
          error: "",
          data: action.payload.replace
            ? action.payload.reviews
            : [...state.reviews.data, ...action.payload.reviews],
        },
      };

    case actionTypes.CLEAR_REVIEWS:
      return {
        ...state,
        reviews: {
          ...state.reviews,
          loading: true,
          error: "",
          data: [],
        },
      };

    case actionTypes.CLEAR_A_REPLY:
      delete state.replies.data[action.payload];
      return state;

    case actionTypes.GENERATE_REPLY_REQUEST:
      return {
        ...state,
        replies: {
          ...state.replies,
          loading: true,
        },
      };

    case actionTypes.CACHE_GENERATED_REPLY:
      const new_reply = { [action.payload.key]: action.payload.reply };
      return {
        ...state,
        replies: {
          ...state.replies,
          loading: false,
          data: state.replies.data
            ? { ...state.replies.data, ...new_reply }
            : new_reply,
        },
      };

    case actionTypes.FETCH_LANGUAGE_OPTION_SUCCESS:
      return {
        ...state,
        langOptions: action.payload,
      };

    case actionTypes.SET_NO_MORE_REVIEWS:
      return {
        ...state,
        reviews: { ...state.reviews, hasMore: false, loading: false },
      };

    case actionTypes.FETCH_COMMENTS_SUCCESS:
      return {
        ...state,
        reviews: {
          ...state.reviews,
          comments: {
            ...state.reviews.comments,
            [action.payload.review_id]: {
              data: action.payload.comments,
              // add_new_comments(
              //   state.reviews.comments[action.payload.review_id],
              //   action.payload.comments
              // ),
              next: action.payload.next,
              count: action.payload.total_comments,
            },
          },
        },
      };

    case actionTypes.SEND_COMMENT_SUCCESS:
      return {
        ...state,
        reviews: {
          ...state.reviews,
          comments: {
            ...state.reviews.comments,
            [action.payload.review_id]: {
              ...state.reviews.comments[action.payload.review_id],
              data: [
                action.payload.comment,
                ...state.reviews.comments[action.payload.review_id].data,
              ],
            },
          },
        },
      };

    case actionTypes.SET_COMMENT_LIKE:
      const updated_comments = add_or_remove_liked_user(
        state.reviews.comments[action.payload.review_id],
        action.payload
      );
      return {
        ...state,
        reviews: {
          ...state.reviews,
          comments: {
            ...state.reviews.comments,
            [action.payload.review_id]: updated_comments,
          },
        },
      };

    case actionTypes.DELETE_COMMENT_REQUEST:
      return {
        ...state,
        reviews: {
          ...state.reviews,
          deleting_comment: true,
        },
      };

    case actionTypes.DELETE_COMMENT_SUCCESS:
      const filtered_comments = update_or_filter_comment(
        state.reviews.comments[action.payload.review_id],
        action.payload.comment_id
      );
      return {
        ...state,
        reviews: {
          ...state.reviews,
          deleting_comment: false,
          error: "",
          comments: {
            ...state.reviews.comments,
            [action.payload.review_id]: filtered_comments,
          },
        },
      };

    case actionTypes.DELETE_COMMENT_ERROR:
      return {
        ...state,
        reviews: {
          ...state.reviews,
          deleting_comment: false,
          error: action.payload,
        },
      };

    case actionTypes.EDIT_COMMENT_REQUEST:
      return {
        ...state,
        reviews: {
          ...state.reviews,
          editing_comment: action.payload,
        },
      };

    case actionTypes.EDIT_COMMENT_SUCCESS:
      const edited_comments = update_or_filter_comment(
        state.reviews.comments[action.payload.review_id],
        action.payload.comment_id,
        true,
        action.payload.edited_comment
      );
      return {
        ...state,
        reviews: {
          ...state.reviews,
          editing_comment: {},
          comments: {
            ...state.reviews.comments,
            [action.payload.review_id]: edited_comments,
          },
        },
      };

    case actionTypes.EDIT_COMMENT_ERROR:
      return {
        ...state,
        reviews: {
          ...state.reviews,
          editing_comment: {},
        },
      };

    case actionTypes.SEND_REVIEW_REPLY_SUCCESS:
      return {
        ...state,
        replies: {
          ...state.replies,
          send_success: action.payload,
        },
      };
    case actionTypes.SET_AGGREGATE_SCORE:
      return {
        ...state,
        aggregateScore: action.payload,
      };
    case SET_TO_INITIAL:
      return initialState;

    default:
      return state;
  }
};

export default reviewsReducer;
