export const FETCH_REVIEWS_REQUEST = "FETCH_REVIEWS_REQUEST";
export const FETCH_REVIEWS_SUCCESS = "FETCH_REVIEWS_SUCCESS";
export const FETCH_REVIEWS_ERROR = "FETCH_REVIEWS_ERROR";
export const CLEAR_REVIEWS = "CLEAR_REVIEWS";
export const SET_NO_MORE_REVIEWS = "SET_NO_MORE_REVIEWS";

export const CLEAR_A_REPLY = "CLEAR_A_REPLY";
export const GENERATE_REPLY_REQUEST = "GENERATE_REPLY_REQUEST";
export const CACHE_GENERATED_REPLY = "CACHE_GENERATED_REPLY";
export const FETCH_LANGUAGE_OPTION_SUCCESS = "FETCH_LANGUAGE_OPTION_SUCCESS";
export const GENERATE_REPLY_ERROR = "GENERATE_REPLY_ERROR";
export const SEND_REVIEW_REPLY_SUCCESS = "SEND_REVIEW_REPLY_SUCCESS"
export const SEND_REVIEW_REPLY_ERROR = "SEND_REVIEW_REPLY_ERROR"

export const FETCH_COMMENTS_SUCCESS = "FETCH_COMMENTS_SUCCESS";
export const SEND_COMMENT_SUCCESS = "SEND_COMMENT_SUCCESS";
export const SET_COMMENT_LIKE = "SET_COMMENT_LIKE";
export const DELETE_COMMENT = "DELETE_COMMENT";
export const DELETE_COMMENT_REQUEST = "DELETE_COMMENT_REQUEST";
export const DELETE_COMMENT_SUCCESS = "DELETE_COMMENT_SUCCESS";
export const DELETE_COMMENT_ERROR = "DELETE_COMMENT_ERROR";
export const EDIT_COMMENT_REQUEST = "EDIT_COMMENT_REQUEST";
export const EDIT_COMMENT_SUCCESS = "EDIT_COMMENT_SUCCESS";
export const EDIT_COMMENT_ERROR = "EDIT_COMMENT_ERROR";
export const SET_AGGREGATE_SCORE = "SET_AGGREGATE_SCORE";
