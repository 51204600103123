export const FETCH_USERS_REQUEST = "FETCH_USERS_REQUEST";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_SUCCESS_APPEND = "FETCH_USERS_SUCCESS_APPEND";
export const FETCH_USERS_FAILURE = "FETCH_USERS_FAILURE";

export const SET_SELECTED_CHAT_USER = "SET_SELECTED_CHAT_USER";
export const UNSET_SELECTED_CHAT_USER = "UNSET_SELECTED_CHAT_USER";
export const UPDATE_CHAT_USER = "UPDATE_CHAT_USER";
export const UPDATE_CHAT_USER_PROP = "UPDATE_CHAT_USER_PROP";
export const UPDATE_OR_ADD_CHAT_USER_AND_RESHUFFLE =
  "UPDATE_OR_ADD_CHAT_USER_AND_RESHUFFLE";

export const FETCH_USER_MESSAGES_REQUEST = "FETCH_USER_MESSAGES_REQUEST";
export const FETCH_USER_MESSAGES_SUCCESS = "FETCH_USER_MESSAGES_SUCCESS";
export const FETCH_USER_MESSAGES_FAILURE = "FETCH_USER_MESSAGES_FAILURE";
export const PREPEND_USER_MESSAGES = "PREPEND_USER_MESSAGES";
export const ADD_TO_USER_MESSAGES = "ADD_TO_USER_MESSAGES";
export const UPDATE_USER_MESSAGE = "UPDATE_USER_MESSAGE";
export const UPDATE_NEW_USER_NOTIFICATION = "UPDATE_NEW_USER_NOTIFICATION";
export const ADD_UNSENT_MESSAGE = "ADD_UNSENT_MESSAGE";
export const UNSET_USER_MESSAGES = "UNSET_USER_MESSAGES"

export const FETCH_CHANNELS_SUCCESS = "FETCH_CHANNELS_SUCCESS";
export const SET_SELECTED_CHANNEL = "SET_SELECTED_CHANNEL";
export const SET_LOADING_ONCLICK = "SET_LOADING_ONCLICK" 
export const EDIT_GUEST_REQ_SUC = "EDIT_GUEST_REQ_SUC" 
export const SET_SUCCESS_MESS_TO_INITIAL = "SET_SUCCESS_MESS_TO_INITIAL" 
export const UPDATE_SUGGESTED_REPLY = "UPDATE_SUGGESTED_REPLY" 
export const SAVE_CHAT_RESOLUTION = "SAVE_CHAT_RESOLUTION" 
export const SAVE_GUEST_ENAGGEMENT = "SAVE_GUEST_ENAGGEMENT" 
export const SAVE_INTENTS = "SAVE_INTENTS" 
export const SET_NEW_NOTI_USER_FROM_API = "SET_NEW_NOTI_USER_FROM_API" 
export const SAVE_CHAT_ANALYTICS_DATA = "SAVE_CHAT_ANALYTICS_DATA" 
export const UPDATE_MESSAGE = "UPDATE_MESSAGE" 
export const SET_BROADCAST_LIST = "SET_BROADCAST_LIST" 
export const SET_SELECTED_CHAT_BROADCAST = "SET_SELECTED_CHAT_BROADCAST" 
export const UNSET_SELECTED_CHAT_BROADCAST = "UNSET_SELECTED_CHAT_BROADCAST" 
export const FETCH_BROADCAST_MESSAGES_SUCCESS = "FETCH_BROADCAST_MESSAGES_SUCCESS" 
export const UPDATE_GROUP_MESSAGE = "UPDATE_GROUP_MESSAGE" 
export const FETCH_GROUP_CHANNELS_SUCCESS = "FETCH_GROUP_CHANNELS_SUCCESS" 
export const ADD_TO_GROUP_MESSAGES = "ADD_TO_GROUP_MESSAGES" 
export const UNSET_Group_MESSAGES = "UNSET_Group_MESSAGES" 
export const FETCH_BROADCAST_FAILURE = "FETCH_BROADCAST_FAILURE" 
export const UPADATE_CAMPAIGN_NAME_FILTER = "UPADATE_CAMPAIGN_NAME_FILTER" 